<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true">
      <img src="../../assets/loader.gif" />
    </vue-element-loading>
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-2>
        <v-card style="min-width:80vw" outlined tile>
          <v-layout pa-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:18px;font-weight:400">About Us</span>
            </v-flex>
          </v-layout>
          <v-layout pa-4 wrap v-for="(item,i) in aboutus" :key="i">
            <v-flex pt-2 xs12 sm12 md12>
              <v-layout pt-4 wrap>
                <v-flex xs6 sm6 md6 text-center>
                  <img v-if="item._id == aboutUsId" :src="url" height="100px" width="auto" />
                  <img
                    v-else
                    :src="baseURL+'/u/'+item.image"
                    :id="'aboutus'+item._id"
                    height="100px"
                    width="auto"
                  />
                </v-flex>
                <v-flex xs6 sm6 md6 text-center align-center>
                  <input
                    ref="onFileChanged"
                    :id="item._id"
                    accept="image/*"
                    type="file"
                    @change="onFileChanged"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea
                ref="title"
                label="Title"
                v-model="item.title"
                color="#26af82"
                rows="2"
                required
              ></v-textarea>
              <v-textarea
                ref="title"
                label="Body"
                color="#26af82"
                v-model="item.body"
                rows="8"
                required
              ></v-textarea>
            </v-flex>

            <v-flex pb-4 xs12 sm12 md12>
              <v-layout wrap text-center>
                <v-flex px-2 xs6 sm6 md6>
                  <v-btn
                    @click="item._id!='newOne' ? updateAboutUs(item): addAboutUs(item) "
                    small
                    dark
                    :ripple="false"
                    class="text-capitalize"
                    color="#26af82"
                    width="250"
                  >
                    <span v-if="item._id!='newOne'">Update Section</span>
                    <span v-else>Add About Us</span>
                  </v-btn>
                </v-flex>
                <v-flex px-2 xs6 sm6 md6>
                  <v-btn
                    @click="item._id!='newOne' ? deleteAboutUs(item,i): cancelAdd(i) "
                    small
                    dark
                    :ripple="false"
                    class="text-capitalize"
                    color="red"
                    width="250"
                  >
                    <span v-if="item._id!='newOne'">Delete</span>
                    <span v-else>Cancel</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-if="showAddBtn" wrap>
            <v-flex py-4 xs12 sm12 md12 text-center>
              <v-btn
                @click="addnewAboutUs()"
                small
                dark
                :ripple="false"
                class="text-capitalize"
                color="#26af82"
                width="250"
              >Add New About Us</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      aboutus: [],
      url: null,
      logo: null,
      aboutUsId: null,
      addAboutUsId: null,
      showAddBtn: true
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    addnewAboutUs() {
      this.aboutus.push({
        title: null,
        body: null,
        _id: "newOne"
      });
      this.showAddBtn = false;
    },
    cancelAdd(index) {
      this.aboutus.splice(index, 1);
      this.showAddBtn = true;
    },
    addAboutUs(item) {
      this.appLoading = true;
      var data = {};
      data["title"] = item.title;
      data["body"] = item.body;
      axios({
        method: "POST",
        url: "/aboutus/add",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showAddBtn = true;
          this.addAboutUsId = response.data.id;
          this.showSnackBar = true;
          if (this.logo != null) {
            this.upLogo();
          } else {
            this.loadData();
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    },
    loadData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/aboutus/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.aboutus = [];
          if (response.data.status) {
            for (var i = 0; i < response.data.data.length; i++) {
              this.aboutus.push({
                title: response.data.data[i].title,
                body: response.data.data[i].body,
                _id: response.data.data[i]._id,
                image: response.data.data[i].image
              });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    },

    onFileChanged(event) {
      if (event.target.files[0].size > 5242880) {
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        document.getElementById("aboutusImage").value = "";
        return;
      } else {
        this.logo = event.target.files[0];
        this.aboutUsId = event.target.id;
        this.url = URL.createObjectURL(this.logo);
      }
    },
    updateAboutUs(item) {
      this.appLoading = true;
      var data = {};
      this.aboutUsId = item._id;
      data["id"] = item._id;
      data["title"] = item.title;
      data["body"] = item.body;
      axios({
        method: "POST",
        url: "/aboutus/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          if (this.logo != null) {
            this.upLogo();
          } else {
            this.loadData();
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    },
    upLogo: function() {
      let formData = new FormData();
      formData.append("image", this.logo);
      if (this.addAboutUsId == null) {
        formData.append("id", this.aboutUsId);
      } else {
        formData.append("id", this.addAboutUsId);
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/aboutus/image",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.logo = null;
            this.loadData();
            if (this.addAboutUsId != null) {
              document.getElementById("newOne").value = "";
            } else {
              document.getElementById(this.aboutUsId).value = "";
            }
            this.aboutUsId = null;
            this.addAboutUsId = null;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    },
    deleteAboutUs(item, index) {
      if (item._id == "newOne") {
        this.aboutus.splice(index, 1);
        // this.aboutus.pop();
      } else {
        this.appLoading = true;
        var data = {};
        data["id"] = item._id;
        axios({
          method: "POST",
          url: "/aboutus/remove",
          headers: {
            "x-auth-token": localStorage.getItem("token")
          },
          data: data
        })
          .then(response => {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.loadData();
          })
          .catch(err => {
            this.msg = err;
            this.$router.push("/ServerError");
          });
      }
    }
  }
};
</script>